import React, { useContext } from "react";
import { ContextoAplicacion } from "../../contextos/ContextoAplicacion";
import ContenedorModal from "./ContenedorModal";

const Modal = () => {
  const contexto = useContext(ContextoAplicacion);

  return (
    <ContenedorModal open={contexto.modal.abierto}>
      <div className="w-full flex flex-wrap justify-center items-center uppercase">
        <div className="w-full my-5">
          <span className="text-cendiatra text-17px  leading-20px font-bold uppercase text-beltranColorPrimario">
            {contexto.modal.titulo}
          </span>
          <span className="text-cendiatra text-17px  mb-50px leading-20px uppercase">
            {contexto.modal.subtitulo}
          </span>
        </div>
        <div className="w-full flex flex-wrap justify-center mt-50px leading-20px text-17px uppercase mb-5">
          {contexto.modal.contenido}
        </div>
        {contexto.modal.botones.length > 0 ? (
          <div className="w-full flex justify-evenly items-center mt-50px mb-80px text-17px text-beltranColorSecundario ">
            {contexto.modal.botones.map((boton) => (
              <button
                key={boton.nombre}
                className="hover:opacity-80 items-center group relative w-44 h-14 flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-beltranColorPrimario bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 "
                type="button"
                onClick={boton.click}
              >
                {" "}
                {boton.nombre}
              </button>
            ))}
          </div>
        ) : null}
      </div>
    </ContenedorModal>
  );
};
export default Modal;
