import React from "react";

const BotonSimple = ({ texto, deshabilitado, funcionClick, tipoDeBoton }) => {
  return (
    <button
      className={`w-auto h-40px p-1.5 rounded-lg ${
        deshabilitado ? "bg-gray-400" : "bg-beltranColorPrimario"
      }  text-white  font-bold text-lg mx-25px`}
      disabled={deshabilitado}
      onClick={funcionClick}
      type={tipoDeBoton}
    >
      {texto}
    </button>
  );
};

export default BotonSimple;
