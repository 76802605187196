import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import "./estilos/App.css";
import Home from "./Home";
import Landing from "./paginas/Landing";
import { NavegacionContexto } from "./contextos/ContextoNavegacion.js";

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
  return (
    <div className="App notranslate">
      <AuthenticatedTemplate>
        <NavegacionContexto>
          <Home />
        </NavegacionContexto>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Landing />
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function App() {
  return <MainContent />;
}
