import React from "react";

const CeldaConBoton = ({ funcionClick, imagen, alt, size }) => {
  return (
    <div
      className="w-full flex justify-center items-center cursor-pointer"
      onClick={funcionClick}
    >
      <img
        className={size ? `w-${size} h-${size}` : "w-6 h-6"}
        src={imagen}
        alt={alt ? alt : "Agregar"}
        title={alt ? alt : "Imagen"}
      />
    </div>
  );
};

export default CeldaConBoton;
