import React from "react";

const CeldaTextoSimple = ({ informacion }) => {
  return (
    <div className="w-full flex flex-wrap justify-evenly items-center">
      <span className="w-full flex justify-evenly items-center">
        {informacion}
      </span>
    </div>
  );
};

export default CeldaTextoSimple;
