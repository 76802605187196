import React from "react";
import fondoApp from "../imagenes/balanzaFondo.png";

const PaginaPrincipal = () => {
  return (
    <div className="min-h-screen w-full flex flex-wrap items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-AppBg bg-cover">
      <img className="w-4/12 border-opacity-50" src={fondoApp} alt="fondo" />
    </div>
  );
};

export default PaginaPrincipal;
