import React, { useContext } from "react";

import MenuDinamico from "./MenuDinamico.jsx";
import bancoImagen from "../../imagenes/bancoImagen.png";

const BarraNavegacion = () => {
  const arrayMenus = [
    // MENU COMERCIAL
    {
      titulo: "Banco de Bogotá",
      imagen: bancoImagen,
      ruta: "/bancodebogota",
    },
    {
      titulo: "Banco Popular",
      imagen: bancoImagen,
      ruta: "/bancopopular",
    },
  ];

  return (
    <>
      <MenuDinamico datos={arrayMenus} />
    </>
  );
};

export default BarraNavegacion;
