import React from "react";
import FormularioCrearProceso from "../components/modificarProcesos/FormularioCrearProceso";
import { ContenedorPagina } from "../components/contenedores/ContenedorPagina";

const CrearProcesos = () => {
  return (
    <ContenedorPagina>
      <FormularioCrearProceso />
    </ContenedorPagina>
  );
};

export default CrearProcesos;
