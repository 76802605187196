export const ContenedorPagina = ({ tituloPagina, children }) => {
  let clasesCss =
    "min-h-screen w-full flex flex-wrap items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-AppBg bg-cover";

  return (
    <div className={clasesCss}>
      <div className="w-95% flex justify-center items-start flex-wrap">
        <div className="w-full flex flex-wrap justify-center items-center">
          <span className="text-center text-2xl font-bold text-beltranColorPrimario mb-10 uppercase">
            {tituloPagina}
          </span>
        </div>
        <div className="bg-white w-11/12 p-4 rounded rounded-t-3xl rounded-b-3xl border border-opacity-50 border-cendiatra shadow-2xl mt-5">
          <div className="bg-white px-8 pt-2 rounded-t-3xl rounded-b-3xl">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
