import React, { useContext } from "react";
import { ContextoAplicacion } from "../../../contextos/ContextoAplicacion";

const CeldaModal = ({ texto, titulo }) => {
  const contextoAplicacion = useContext(ContextoAplicacion);

  const manejarAbrirModal = () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: (
        <>
          <span className="font-bold">{titulo}</span>
        </>
      ),
      contenido: (
        <div className="w-full h-auto overflow-y-auto">
          <span>{texto}</span>,
        </div>
      ),
      botones: [
        {
          nombre: "Regresar",
          click: cerrarModal,
        },
      ],
    });
  };

  const cerrarModal = () => {
    contextoAplicacion.setModal({ abierto: false, botones: [] });
  };

  return (
    <div className="w-full flex justify-center items-center">
      <span
        className="w-full truncate underline cursor-pointer"
        onClick={manejarAbrirModal}
      >
        {texto}
      </span>
    </div>
  );
};

export default CeldaModal;
