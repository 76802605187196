export const encabezadosExportarDatosTabla = [
  {
    label: "Número Identificación",
    key: "numero_identificacion",
  },

  {
    label: "Nombre",
    key: "nombre",
  },
  {
    label: "Fecha Diligencia",
    key: "fechaDiligencia",
  },
  {
    label: "Ciudad",
    key: "ciudad",
  },
  {
    label: "Número de juzgado",
    key: "numero_de_juzgado",
  },
  {
    label: "Juzgado",
    key: "juzgado",
  },
  {
    label: "Fag",
    key: "fagTexto",
  },

  {
    label: "Radicado",
    key: "radicado",
  },
  {
    label: "Obligación",
    key: "obligacion",
  },
  {
    label: "Obligaciones",
    key: "obligaciones",
  },
  {
    label: "Correo Electrónico",
    key: "correo_electronico",
  },
  {
    label: "Tipo De Proceso",
    key: "tipoProcesoTexto",
  },
  {
    label: "Radicado Completo",
    key: "radicado_completo",
  },
  {
    label: "Investigación De Bienes",
    key: "investigacionDeBienesTexto",
  },
  {
    label: "Etapa Procesal",
    key: "estapaProcesalTexto",
  },
  {
    label: "Etapa Procesal Medidas",
    key: "etapaProcesalMedidasTexto",
    requerido: false,
  },
  // {
  //   label: "Libranza",
  //   key: "libranza",
  // },
  {
    label: "Fecha Ultima Revisíon",
    key: "fecha_ult_revision",
  },
  {
    label: "Fecha Presentación Demanda",
    key: "fecha_presen_demanda",
  },
  {
    label: "Observaciones Cuaderno Principal",
    key: "observaciones_cuaderno_principal",
  },
  {
    label: "Inventario Bienes",
    key: "inventario_bienes",
  },
  {
    label: "Tipo De Bien",
    key: "tipoDeBienTexto",
  },
  {
    label: "Fmi 1",
    key: "fmi_1",
  },
  {
    label: "Fmi 2",
    key: "fmi_2",
  },
  {
    label: "Placa 1",
    key: "placa_1",
  },
  {
    label: "Placa 2",
    key: "placa_2",
  },
  {
    label: "Registro Mercantil",
    key: "registro_mercantil",
  },
  {
    label: "Pagador",
    key: "pagador",
  },
  {
    label: "labels Judiciales",
    key: "labels_judiciales",
  },
  {
    label: "Url Documentos",
    key: "urlDocumentos",
  },
  {
    label: "Plan Compartir",
    key: "planCompartirTexto",
  },
  {
    label: "Medida Efectiva",
    key: "medidaEfectivaTexto",
  },
  {
    label: "Negociación",
    key: "negociacion",
  },
  {
    label: "Factura Avance Procesal",
    key: "facturaAvanceProcesal",
  },
  {
    label: "Pago Avance Procesal",
    key: "pagoAvanceProcesal",
  },
  {
    label: "Fecha de Recepción",
    key: "FechaRecepcion",
  },
  {
    label: "Observaciones",
    key: "observaciones",
  },
];
