import React, { useRef, useState, useContext } from "react";
import { CSVLink } from "react-csv";
import BotonSimple from "../botones/BotonSimple";

const ExportarCSV = ({ encabezados, nombreArchivo, nombreBoton, datos }) => {
  const csvLinkEl = useRef();

  const manejarObtenerDatos = async () => {
    csvLinkEl.current.link.click();
  };

  const csvReport = {
    data: datos,
    headers: encabezados,
    filename: nombreArchivo,
  };

  return (
    <div>
      <BotonSimple
        texto={nombreBoton}
        funcionClick={(e) => manejarObtenerDatos(e)}
        tipoDeBoton={"button"}
      />
      <CSVLink {...csvReport} ref={csvLinkEl}></CSVLink>
    </div>
  );
};

export default ExportarCSV;
