import React, { useState, createContext } from "react";

export const ContextoAplicacion = createContext("Default Value");

export const Provider = ({ children }) => {
  const [modal, setModal] = useState({
    abierto: false,
    titulo: "",
    contenido: "",
    botones: [],
  });
  const [cargando, setCargando] = useState(false);

  return (
    <ContextoAplicacion.Provider
      value={{
        modal: modal,
        setModal: setModal,
        cargando: cargando,
        setCargando: setCargando,
      }}
    >
      {children}
    </ContextoAplicacion.Provider>
  );
};
