import React, { useEffect, useState, useMemo } from "react";
import { useTable, usePagination } from "react-table";
import moment from "moment";
import FiltroTexto from "./FiltroTexto";
import { useFilters } from "react-table/dist/react-table.development";

const TablaConFiltro = ({
  ocultarTabla,
  data,
  columns,
  cambiarFiltro,
  filasPorPagina = 50,
  paginacion = true,
}) => {
  const [numerosParaMostrar, setNumerosparaMostrar] = useState([]);

  const [numeroInicio, setNumeroInicio] = useState(0);

  const [numeroFinal, setNumeroFinal] = useState(filasPorPagina);

  const initialStateMemo = useMemo(() => {
    if (paginacion) {
      return { pageIndex: 0, pageSize: filasPorPagina };
    }
    return { pageIndex: 0 };
  }, [paginacion]);

  const filterTypes = React.useMemo(
    () => ({
      dobleTexto: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue.Fila1)
                .toLowerCase()
                .includes(String(filterValue).toLowerCase()) ||
                String(rowValue.Fila2)
                  .toLowerCase()
                  .includes(String(filterValue).toLowerCase())
            : true;
        });
      },
      fecha: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? moment(rowValue).format("DD/MM/YYYY") ==
                moment(filterValue).format("DD/MM/YYYY")
            : true;
        });
      },
      lista: (page, id, filterValue) => {
        if (!filterValue) return page;
        return page.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined ? rowValue == filterValue : false;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: FiltroTexto,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canNextPage,
    nextPage,
    previousPage,
    pageCount,
    gotoPage,
    pageOptions,
    state: { pageIndex, filters },
    setAllFilters,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        ...initialStateMemo,
      },
    },
    useFilters,
    usePagination
  );

  useEffect(() => {
    const crearArregloNumeros = () => {
      if (pageOptions.length > filasPorPagina) {
        let numeros = pageOptions.slice(numeroInicio, numeroFinal);
        setNumerosparaMostrar(numeros);
      } else {
        setNumerosparaMostrar(pageOptions);
      }
    };
    crearArregloNumeros();
  }, [data, pageOptions, numeroInicio]);

  useEffect(() => {
    const hasta =
      pageIndex !== 0 ? pageIndex + 1 * filasPorPagina : filasPorPagina;
    setNumeroInicio(pageIndex);
    setNumeroFinal(hasta);
  }, [pageIndex]);

  const limpiarFiltros = () => {
    setAllFilters(
      filters.map((filtro) => {
        return {
          id: filtro.id,
          value: "",
        };
      })
    );
  };

  useEffect(() => {
    limpiarFiltros();
  }, [cambiarFiltro]);

  return (
    <>
      <table
        className="flex flex-wrap w-full border-separate table-fixed  overflow-x-scroll"
        style={{ height: "100%", maxWidth: "1920px" }}
        {...getTableProps()}
      >
        <thead
          className="flex justify-center items-center w-full flex-nowrap text-14px "
          style={{ width: "calc(100% - 16px)" }}
        >
          {headerGroups.map((headerGroup) => (
            <tr
              className="flex w-full flex-nowrap text-center"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((header) => (
                <th
                  {...header.getHeaderProps({
                    className: `${header.className} ${header.headerStyle} bg-beltranColorPrimario flex flex-wrap justify-center items-center content-start sticky`,
                  })}
                >
                  {header.canFilter ? (
                    <div
                      className="w-full flex-wrap flex bg-cendiatra-verde-5 rounded-md text-14px h-14 "
                      style={{ paddingTop: "5px" }}
                    >
                      <div className="w-full flex justify-center items-center  rounded-md text-14px">
                        {header.render("Header")}
                      </div>
                      <div
                        className="w-full flex justify-evenly items-center mb-2 text-13px  "
                        style={{ margin: "" }}
                      >
                        {header.render("Filter")}
                      </div>
                    </div>
                  ) : (
                    header.render("Header")
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          className={`${
            ocultarTabla ? "hidden" : ""
          } flex w-full flex-wrap h-550  text-13px content-start relative`}
          {...getTableBodyProps()}
        >
          {page.map((row, index) => {
            {
              prepareRow(row);
              return (
                <tr
                  className="flex w-auto flex-nowrap text-center justify-center items-center border border-cendiatra-verde-4 my-2 rounded-lg "
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        style={{ backgroundColor: "white" }}
                        {...cell.getCellProps({
                          className: `${cell.column.className} ${cell.column.cellStyles} `,
                        })}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            }
          })}
        </tbody>
      </table>
      <div>
        {data.length > filasPorPagina && paginacion ? (
          <div className="w-full flex justify-center my-5">
            <button
              className="text-5xl text-gray-400 pb-2 ml-2"
              onClick={() => gotoPage(0)}
            >
              {"«"}
            </button>{" "}
            <button
              className="text-5xl text-gray-400 pb-2 ml-2"
              onClick={() => previousPage()}
            >
              {"‹"}
            </button>{" "}
            {numerosParaMostrar.map((elemento) => (
              <div
                key={elemento}
                className={
                  pageIndex === elemento
                    ? "z-10 bg-beltranColorPrimario border-beltranColorPrimario text-white relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer rounded-full "
                    : "  text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer rounded-full"
                }
                onClick={() => gotoPage(elemento)}
              >
                {elemento + 1}
              </div>
            ))}
            <button
              className="text-5xl text-gray-400 pb-2 ml-2"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {"›"}
            </button>{" "}
            <button
              className="text-5xl text-gray-400 pb-2 ml-2"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {"»"}
            </button>{" "}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TablaConFiltro;
