export const ErroresDeCampo = ({erroresFormulario, nombre}) => {
  if (
    typeof nombre !== 'string' ||
    (typeof nombre === 'string' &&
      (erroresFormulario === undefined ||
        (erroresFormulario !== undefined &&
          erroresFormulario[nombre] === undefined)))
  ) {
    return null
  }

  return (
    <span className="text-red-500 w-full">
      {erroresFormulario[nombre]['message']}
    </span>
  )
}
