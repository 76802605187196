import React, { useContext } from "react";
import { ContextoNavegacion } from "../../contextos/ContextoNavegacion";
import { NavLink } from "react-router-dom";

const Submenu = ({
  titulo,
  imagen,
  ruta,
  funcion,
  deshabilitado,
  children,
}) => {
  const contextoNavegacion = useContext(ContextoNavegacion);

  return (
    <>
      <NavLink
        className={` w-full ${deshabilitado ? "pointer-events-none" : ""} `}
        to={`${ruta}`}
      >
        <div
          onMouseEnter={() => contextoNavegacion.setEnlaceActivo(titulo)}
          onClick={() => (funcion ? funcion() : "")}
          className={"flex w-full justify-center items-center h-10 my-1"}
        >
          <img className="w-7 h-7 m-2" src={imagen} alt="serviceImg" />
          <div
            className={contextoNavegacion.abrirMenu ? "flex w-full" : "hidden"}
          >
            <span
              className={
                contextoNavegacion.enlaceActivo === titulo && !deshabilitado
                  ? " text-lg text-white w-full bg-beltranColorPrimario bg-cover bg-center h-10 rounded flex items-center pl-2"
                  : `${
                      deshabilitado
                        ? "text-gray-200"
                        : "text-beltranColorPrimario"
                    } text-lg w-full flex`
              }
            >
              {titulo}
            </span>
          </div>
        </div>
      </NavLink>
      <div> {children} </div>
    </>
  );
};

export default Submenu;
