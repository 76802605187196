import React, { useEffect, useContext } from "react";
import dotMenu from "../../imagenes/dotMenu.png";
import Submenu from "./Submenu";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { ContextoNavegacion } from "../../contextos/ContextoNavegacion";
import { useMsal } from "@azure/msal-react";
import cerrarImagen from "../../imagenes/cerrarImagen.png";
import balanzaImagen from "../../imagenes/balanzaImagen.png";
import Logo from "../../imagenes/LOGOBELTRAN.png";

const MenuDinamico = ({ datos }) => {
  const location = useLocation();
  const { instance } = useMsal();
  const handleLogout = (logoutType) => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  let fechaActual = moment().format("DD/MM/YYYY");

  const contextoNavegacion = useContext(ContextoNavegacion);
  const applicationVersion = "V. 1." + process.env.REACT_APP_VERSION + " beta";

  useEffect(() => {
    abrirMenuAutomaticamente();
  }, [location]);

  const abrirMenuAutomaticamente = () => {
    if (location.pathname == "/") {
      contextoNavegacion.setAbrirMenu(true);
    }
  };

  const MostrarMenu = () => {
    if (contextoNavegacion.abrirMenu === true) {
      contextoNavegacion.setAbrirMenu(false);
      contextoNavegacion.setEnlaceActivo("");
    } else {
      contextoNavegacion.setAbrirMenu(true);
    }
  };

  useEffect(() => {
    contextoNavegacion.setEnlaceActivo("");
  }, [contextoNavegacion.setEnlaceActivo]);

  return (
    <>
      <div
        className={
          contextoNavegacion.abrirMenu
            ? " bg-transparent w-full h-full fixed flex  justify-start  content-start z-40 "
            : "hidden"
        }
        onClick={contextoNavegacion.abrirMenu ? () => MostrarMenu() : null}
      ></div>
      <div
        className={
          contextoNavegacion.abrirMenu
            ? "bg-white sm:w-2/5 md:w-4/12 lg:w-4/12 xl:w-3/12 2xl:w-1/5 h-full fixed flex border  border-opacity-50 border-beltranColorPrimario shadow-2xl rounded flex-wrap justify-start  content-start z-50 rounded-tr-3xl rounded-br-3xl transition-color duration-100"
            : "bg-white w-customxl 2xl:w-custom2xl  flex border fixed  border-opacity-50 border-beltranColorPrimario shadow-2xl flex-wrap justify-start h-full content-start z-50 rounded-md transition-color duration-100"
        }
        onMouseLeave={() => contextoNavegacion.setEnlaceActivo("")}
      >
        <div
          className={
            contextoNavegacion.abrirMenu
              ? "bg-white   h-5/6 rounded-lg w-full transition-color duration-75 "
              : "bg-white w-full h-5/6 rounded-lg transition-color duration-75"
          }
        >
          <div
            className={`w-full  ${
              contextoNavegacion.abrirMenu ? "h-28" : "h-16"
            }  flex  justify-center items-center font-mon bg-beltranColorPrimario bg-center bg-cover ${
              contextoNavegacion.abrirMenu
                ? "rounded-tr-2xl rounded-br-2xl"
                : "rounded-md"
            } z-50 `}
            onMouseEnter={() => contextoNavegacion.setEnlaceActivo("")}
          >
            <div
              onClick={() => MostrarMenu()}
              className={
                contextoNavegacion.abrirMenu
                  ? "flex w-1/12 justify-center items-center cursor-pointer -mt-20"
                  : "flex w-4/12 justify-center items-center cursor-pointer -mt-10 ml-3 z-50"
              }
              style={{ marginBottom: "-30px" }}
            >
              <img
                className={
                  contextoNavegacion.abrirMenu
                    ? "h-6 w-2  text-white text-lg z-50 "
                    : "h-6 w-2 xl:w-2 2xl:w-2  text-white text-lg z-50 -ml-2"
                }
                src={dotMenu}
                alt="dotMenu"
              ></img>
            </div>

            <div className="w-8/12 flex justify-center items-center flex-wrap ">
              <span
                className={
                  contextoNavegacion.abrirMenu
                    ? "w-full h-auto text-16px text-white text-center font-bold"
                    : "hidden"
                }
              ></span>
              <span
                className={
                  contextoNavegacion.abrirMenu
                    ? "w-full h-6  text-13px  text-white text-center flex items-center justify-center "
                    : "hidden"
                }
              >
                Beltrán Mejía
              </span>
              <span
                className={
                  contextoNavegacion.abrirMenu
                    ? "w-full h-6  text-13px text-white text-center flex items-center justify-center leading-15px"
                    : "hidden"
                }
              >
                Asesorías Y Proyectos Sas
              </span>
              <span
                className={
                  contextoNavegacion.abrirMenu
                    ? "w-full h-6 text-13px text-white text-center flex items-center justify-center"
                    : "hidden"
                }
              >
                {fechaActual}
              </span>
            </div>
            <div
              className={
                contextoNavegacion.abrirMenu
                  ? "w-4/12 flex justify-center"
                  : "w-1/3 hidden  justify-center"
              }
            >
              <img className="w-10/12" src={Logo} alt="Balanza" />
            </div>
          </div>

          <div className="ml-5 ">
            <div className="w-full flex flex-wrap ">
              <div className="w-full mt-5">
                <div className="w-full" style={{ minHeight: "400px" }}>
                  {datos.map((elemento) => (
                    <div
                      key={elemento.titulo}
                      className={
                        contextoNavegacion.abrirMenu
                          ? "flex  flex-nowrap"
                          : "flex flex-nowrap -ml-5"
                      }
                    >
                      <Submenu
                        titulo={elemento.titulo}
                        imagen={elemento.imagen}
                        ruta={elemento.ruta}
                        deshabilitado={elemento.deshabilitado}
                      >
                        {elemento.subMenus ? (
                          contextoNavegacion.enlaceActivo ===
                          elemento.titulo ? (
                            <div
                              className="w-auto fixed bg-white rounded z-50"
                              onClick={() =>
                                contextoNavegacion.setEnlaceActivo("")
                              }
                              onMouseLeave={() =>
                                contextoNavegacion.setEnlaceActivo("")
                              }
                            >
                              {elemento.subMenus.map((subMenu) => (
                                <NavLink
                                  to={subMenu.ruta}
                                  className="flex justify-start flex-nowrap items-center p-2 rounded border-t-2 border-l-2 border-r-2"
                                >
                                  <img
                                    className="w-7 h-7"
                                    src={subMenu.imagen}
                                    alt="service"
                                  />
                                  <span className="hover:bg-gray-100 ml-2">
                                    {subMenu.titulo}
                                  </span>
                                </NavLink>
                              ))}
                            </div>
                          ) : null
                        ) : null}
                      </Submenu>
                    </div>
                  ))}
                </div>
                <hr className="mt-5 mb-5  -ml-4"></hr>
                <div
                  className={`flex flex-wrap ${
                    contextoNavegacion.abrirMenu ? "" : "-ml-5"
                  } `}
                ></div>

                <div
                  className={
                    contextoNavegacion.abrirMenu
                      ? "flex flex-wrap"
                      : "flex flex-wrap -ml-5"
                  }
                >
                  <Submenu
                    titulo="Cerrar Sesión"
                    imagen={cerrarImagen}
                    funcion={() => handleLogout("redirect")}
                  />
                </div>
                <div
                  className={`flex w-full justify-center items-center mt-16 flex-wrap ${
                    contextoNavegacion.abrirMenu ? "-ml-4" : "-ml-2"
                  }`}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuDinamico;
