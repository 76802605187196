import { ArrayListasDesplegables } from "./ListasDesplegables";

export const ArrayInputs = [
  {
    titulo: "Número Identificación",
    nombre: "numero_identificacion",
    requerido: true,
    tipo: "text",
    campo: "INPUT",
  },

  {
    titulo: "Nombre",
    nombre: "nombre",
    requerido: true,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Fecha Diligencia",
    nombre: "fechaDiligencia",
    requerido: false,
    tipo: "date",
    campo: "INPUT",
  },
  {
    titulo: "Ciudad",
    nombre: "ciudad",
    requerido: false,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Número de juzgado",
    nombre: "numero_de_juzgado",
    requerido: false,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Juzgado",
    nombre: "juzgado",
    requerido: false,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Fag",
    nombre: "fag",
    requerido: false,
    tipo: "text",
    campo: "LISTA",
    opcionesLista: ArrayListasDesplegables.FONDOS.sort((a, b) =>
      a.Nombre.toUpperCase().localeCompare(b.Nombre.toUpperCase())
    ),
  },

  {
    titulo: "Radicado",
    nombre: "radicado",
    requerido: false,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Obligación",
    nombre: "obligacion",
    requerido: false,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Obligaciones",
    nombre: "obligaciones",
    requerido: false,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Correo Electrónico",
    nombre: "correo_electronico",
    requerido: false,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Tipo De Proceso",
    nombre: "tipo_de_proceso",
    requerido: false,
    tipo: "text",
    campo: "LISTA",
    opcionesLista: ArrayListasDesplegables.TIPOS_DE_PROCESOS.sort((a, b) =>
      a.Nombre.toUpperCase().localeCompare(b.Nombre.toUpperCase())
    ),
  },
  {
    titulo: "Radicado Completo",
    nombre: "radicado_completo",
    requerido: false,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Investigación De Bienes",
    nombre: "investigacion_de_bienes",
    requerido: false,
    tipo: "text",
    campo: "LISTA",
    opcionesLista: ArrayListasDesplegables.OPCIONES_POSITIVO_NEGATIVO.sort(
      (a, b) => a.Nombre.toUpperCase().localeCompare(b.Nombre.toUpperCase())
    ),
  },
  {
    titulo: "Etapa Procesal",
    nombre: "etapa_procesal",
    requerido: false,
    tipo: "text",
    campo: "LISTA",
    opcionesLista: ArrayListasDesplegables.ETAPA_PROCESAL.sort((a, b) =>
      a.Nombre.toUpperCase().localeCompare(b.Nombre.toUpperCase())
    ),
  },
  {
    titulo: "Etapa Procesal Medidas",
    nombre: "etapa_procesal_medidas",
    requerido: false,
    tipo: "text",
    campo: "LISTA",
    opcionesLista: ArrayListasDesplegables.ETAPA_PROCESAL_MEDIDAS.sort((a, b) =>
      a.Nombre.toUpperCase().localeCompare(b.Nombre.toUpperCase())
    ),
  },
  // {
  //   titulo: "Libranza",
  //   nombre: "libranza",
  //   requerido: false,
  //   tipo: "text",
  //   campo: "INPUT",
  // },
  {
    titulo: "Fecha Ultima Revisíon",
    nombre: "fecha_ult_revision",
    requerido: false,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Fecha Presentación Demanda",
    nombre: "fecha_presen_demanda",
    requerido: false,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Observaciones Cuaderno Principal",
    nombre: "observaciones_cuaderno_principal",
    requerido: false,
    tipo: "text",
    campo: "AREA",
  },
  {
    titulo: "Inventario Bienes",
    nombre: "inventario_bienes",
    requerido: false,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Tipo De Bien",
    nombre: "tipo_de_bien",
    requerido: false,
    tipo: "text",
    campo: "LISTA",
    opcionesLista: ArrayListasDesplegables.TIPO_DE_BIEN.sort((a, b) =>
      a.Nombre.toUpperCase().localeCompare(b.Nombre.toUpperCase())
    ),
  },
  {
    titulo: "Fmi 1",
    nombre: "fmi_1",
    requerido: false,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Fmi 2",
    nombre: "fmi_2",
    requerido: false,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Placa 1",
    nombre: "placa_1",
    requerido: false,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Placa 2",
    nombre: "placa_2",
    requerido: false,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Registro Mercantil",
    nombre: "registro_mercantil",
    requerido: false,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Pagador",
    nombre: "pagador",
    requerido: false,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Titulos Judiciales",
    nombre: "titulos_judiciales",
    requerido: false,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Url Documentos",
    nombre: "urlDocumentos",
    requerido: false,
    tipo: "text",
    campo: "INPUT",
  },
  {
    titulo: "Plan Compartir",
    nombre: "plan_compartir",
    requerido: false,
    tipo: "text",
    campo: "LISTA",
    opcionesLista: ArrayListasDesplegables.OPCIONES_SI_NO.sort((a, b) =>
      a.Nombre.toUpperCase().localeCompare(b.Nombre.toUpperCase())
    ),
  },
  {
    titulo: "Medida Efectiva",
    nombre: "medida_efectiva",
    requerido: false,
    tipo: "text",
    campo: "LISTA",
    opcionesLista: ArrayListasDesplegables.OPCIONES_SI_NO.sort((a, b) =>
      a.Nombre.toUpperCase().localeCompare(b.Nombre.toUpperCase())
    ),
  },
  {
    titulo: "Negociación",
    nombre: "negociacion",
    requerido: false,
    tipo: "text",
    campo: "LISTA",
    opcionesLista: ArrayListasDesplegables.NEGOCIACION.sort((a, b) =>
      a.Nombre.toUpperCase().localeCompare(b.Nombre.toUpperCase())
    ),
  },
  {
    titulo: "Factura Avance Procesal",
    nombre: "facturaAvanceProcesal",
    requerido: false,
    tipo: "text",
    campo: "LISTA",
    opcionesLista: ArrayListasDesplegables.FACTURA_AVANCE_PROCESAL.sort(
      (a, b) => a.Nombre.toUpperCase().localeCompare(b.Nombre.toUpperCase())
    ),
  },
  {
    titulo: "Pago Avance Procesal",
    nombre: "pagoAvanceProcesal",
    requerido: false,
    tipo: "text",
    campo: "LISTA",
    opcionesLista: ArrayListasDesplegables.PAGO_AVANCE_PROCESAL.sort((a, b) =>
      a.Nombre.toUpperCase().localeCompare(b.Nombre.toUpperCase())
    ),
  },
  {
    titulo: "Fecha de Recepción",
    nombre: "FechaRecepcion",
    requerido: false,
    tipo: "date",
    campo: "INPUT",
  },
  {
    titulo: "Observaciones",
    nombre: "observaciones",
    requerido: false,
    tipo: "text",
    campo: "AREA",
  },
];
