import React, { useEffect, useContext } from "react";
import { Route, Routes } from "react-router";
import PaginaPrincipal from "./paginas/PaginaPrincipal";
import Procesos from "./paginas/Procesos";
import CrearProcesos from "./paginas/CrearProcesos";

// import { ContextApplication } from "./contexto/ApplicationContext.js";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<PaginaPrincipal />} />
      <Route path="/bancodebogota" element={<Procesos banco={"Bogotá"} />} />
      <Route path="/bancopopular" element={<Procesos banco={"Popular"} />} />
      <Route path="/creareditarprocesos" element={<CrearProcesos />} />
    </Routes>
  );
};
export default Router;
