import React from "react";

const CeldaAbrirEnlace = ({ enlace }) => {
  const abrirUrl = () => {
    window.open(enlace, "_blank");
  };

  return (
    <div className="w-full flex justify-center items-center">
      <span
        className="w-full underline cursor-pointer text-beltranColorPrimario"
        onClick={enlace ? abrirUrl : null}
      >
        {enlace ? "Abrir Documentos" : ""}
      </span>
    </div>
  );
};

export default CeldaAbrirEnlace;
