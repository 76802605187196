import React, { useState, useEffect, useContext } from "react";

import TablaConFiltro from "../tablas/TablaConFiltro";
import EncabezadoFondo from "../tablas/celdas/EncabezadoFondo";
import CeldaModal from "../tablas/celdas/CeldaModal";
import CeldaConBoton from "../tablas/celdas/CeldaConBoton";
import BotonEditar from "../../imagenes/botonEditar.png";
import BotonEliminar from "../../imagenes/botonEliminar.png";
import { ContextoAplicacion } from "../../contextos/ContextoAplicacion";
import { useNavigate } from "react-router-dom";
import BotonSimple from "../botones/BotonSimple";
import { NavLink } from "react-router-dom";
import imagenCerrar from "../../imagenes/Salir.png";

import {
  obtenerTiposDeProcesoPorId,
  obtenerEtapaProcesalPorId,
  obtenerEtapaProcesalMedidasPorId,
  obtenerTipoDeBienPorId,
  obtenerNegociacionId,
  obtenerFacturaAvanceProcesalId,
  obtenerPagoAvanceProcesalId,
} from "../../utilidades/FuncionesObtenerNombresListas";
import FiltroListaSeleccionar from "../tablas/FiltroListaSeleccionar";
import CeldaTransformarIdEnTexto from "../tablas/celdas/CeldaTextoSimple";
import CeldaAbrirEnlace from "../tablas/celdas/CeldaAbrirEnlace";
import ExportarCSV from "../EsportarCSV/ExportarCsv";
import { encabezadosExportarDatosTabla } from "../../utilidades/ArrayEncabezadosCsv";

const VerModificarProcesos = ({ banco }) => {
  const [datosBanco, setDatosBanco] = useState([]);
  const [cambiarFiltro, setCambiarFiltro] = useState(false);
  const navigate = useNavigate();

  const contextoAplicacion = useContext(ContextoAplicacion);

  useEffect(() => {
    cargarDatosIniciales();
  }, [banco]);

  const determinarString = (estadoExamen, stringId1, stringId2) => {
    switch (estadoExamen) {
      case 1:
        return stringId1;
      case 2:
        return stringId2;
      default:
        return "";
    }
  };

  const cargarDatosIniciales = () => {
    contextoAplicacion.setCargando(true);

    (async function () {
      const datosBanco =
        banco === "Bogotá"
          ? await (await fetch(`/api/ReadBdB`)).json()
          : await (await fetch(`/api/ReadBdO`)).json();
      setDatosBanco(
        await Promise.all(
          datosBanco.map(async (proceso) => {
            let tipoDeProceso = await obtenerTiposDeProcesoPorId(
              parseInt(proceso?.TIPO_DE_PROCESO)
            );

            let etapaProcesal = await obtenerEtapaProcesalPorId(
              parseInt(proceso?.ETAPA_PROCESAL)
            );

            let etapaProcesalMedidas = await obtenerEtapaProcesalMedidasPorId(
              parseInt(proceso?.ETAPA_PROCESAL_MEDIDAS)
            );

            let tipoDeBien = await obtenerTipoDeBienPorId(
              parseInt(proceso?.TIPO_DE_BIEN)
            );
            let negociacion = await obtenerNegociacionId(
              parseInt(proceso?.NEGOCIACION)
            );
            let facturaAvanceProcesal = await obtenerFacturaAvanceProcesalId(
              parseInt(proceso?.FACTURA_AVANCE_PROCESAL)
            );
            let pagoAvanceProcesal = await obtenerPagoAvanceProcesalId(
              parseInt(proceso?.PAGO_AVANCE_PROCESAL)
            );

            return {
              idProceso: proceso.partitionKey,
              idFila: proceso.rowKey ?? "",
              fechaDiligencia: proceso?.FECHA_DILIGENCIA ?? "",
              ciudad: proceso?.CIUDAD ?? "",
              numero_de_juzgado: proceso?.NUMERO_DE_JUZGADO ?? "",
              juzgado: proceso?.JUZGADO ?? "",
              fag: proceso?.FAG ?? "",
              fagTexto: determinarString(proceso?.FAG ?? "", "FAG", "FNG"),
              numero_identificacion: proceso?.NUMERO_IDENTIFICACION ?? "",
              nombre: proceso?.NOMBRE ?? "",
              radicado: proceso?.RADICADO ?? "",
              obligacion: proceso?.OBLIGACION ?? "",
              obligaciones: proceso?.OBLIGACIONES ?? "",
              correo_electronico: proceso?.CORREO_ELECTRONICO ?? "",
              tipo_de_proceso: proceso?.TIPO_DE_PROCESO ?? "",
              tipoProcesoTexto: tipoDeProceso?.Nombre ?? "",
              radicado_completo: proceso.RADICADO_COMPLETO ?? "",
              investigacion_de_bienes: proceso?.INVESTIGACION_DE_BIENES ?? "",
              investigacionDeBienesTexto: determinarString(
                proceso?.INVESTIGACION_DE_BIENES ?? "",
                "POSITIVO",
                "NEGATIVO"
              ),
              etapa_procesal: proceso?.ETAPA_PROCESAL ?? "",
              estapaProcesalTexto: etapaProcesal?.Nombre ?? "",
              etapa_procesal_medidas: proceso?.ETAPA_PROCESAL_MEDIDAS ?? "",
              etapaProcesalMedidasTexto: etapaProcesalMedidas?.Nombre ?? "",
              // libranza: proceso?.LIBRANZA ?? "",
              fecha_ult_revision: proceso?.FECHA_ULT_REVISION ?? "",
              fecha_presen_demanda: proceso?.FECHA_PRESEN_DEMANDA ?? "",
              observaciones_cuaderno_principal:
                proceso?.OBSERVACIONES_CUADERNO_PRINCIPAL ?? "",
              inventario_bienes: proceso?.INVENTARIO_BIENES ?? "",
              tipo_de_bien: proceso?.TIPO_DE_BIEN ?? "",
              tipoDeBienTexto: tipoDeBien?.Nombre ?? "",
              fmi_1: proceso?.FMI_1 ?? "",
              fmi_2: proceso?.FMI_2 ?? "",
              placa_1: proceso?.PLACA_1 ?? "",
              placa_2: proceso?.PLACA_2 ?? "",
              registro_mercantil: proceso?.REGISTRO_MERCANTIL ?? "",
              pagador: proceso?.PAGADOR ?? "",
              titulos_judiciales: proceso?.TITULOS_JUDICIALES ?? "",
              negociacionTexto: negociacion?.Nombre ?? "",
              negociacion: proceso?.NEGOCIACION ?? "",
              facturaAvanceProcesalTexto: facturaAvanceProcesal?.Nombre ?? "",
              facturaAvanceProcesal: proceso?.FACTURA_AVANCE_PROCESAL ?? "",
              pagoAvanceProcesalTexto: pagoAvanceProcesal?.Nombre ?? "",
              pagoAvanceProcesal: proceso?.PAGO_AVANCE_PROCESAL ?? "",
              FechaRecepcion: proceso?.FECHA_RECEPCION ?? "",
              observaciones: proceso?.OBSERVACIONES ?? "",
              urlDocumentos: proceso?.url_documento ?? "",
              plan_compartir: proceso?.PLAN_COMPARTIR ?? "",
              planCompartirTexto: determinarString(
                proceso?.PLAN_COMPARTIR ?? "",
                "SI",
                "NO"
              ),
              medida_efectiva: proceso?.MEDIDA_EFECTIVA ?? "",
              medidaEfectivaTexto: determinarString(
                proceso?.MEDIDA_EFECTIVA ?? "",
                "SI",
                "NO"
              ),
            };
          })
        )
      );
      contextoAplicacion.setCargando(false);
    })();
  };

  const manejarEditarInformacion = (datos) => {
    const filterTemp = JSON.parse(localStorage.getItem("filters"));
    localStorage.setItem("filtersTemp", JSON.stringify(filterTemp));

    // const cellTemp = JSON.parse(localStorage.getItem("cell"));
    // localStorage.setItem("cellTemp", JSON.stringify(cellTemp));
    // debugger;
    // const data = {
    //   titulo: "Editar proceso",
    //   datosProceso: datos,
    //   banco: banco,
    //   tituloBoton: "Editar",
    // };

    // const handleClick = () => {
    //   window.open(
    //     "/creareditarprocesos",
    //     localStorage.setItem("items", JSON.stringify(data))
    //   );
    // };

    // return <button onClick={handleClick()}></button>;

    navigate("/creareditarprocesos", {
      state: {
        titulo: "Editar proceso",
        datosProceso: datos,
        banco: banco,
        tituloBoton: "Editar",
      },
    });
  };
  const abrirModalConfirmacion = (transaccion) => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: `Se ha ${transaccion} el proceso correctamente`,
      contenido: "",
      botones: [
        {
          nombre: "Regresar",
          click: volverATabla,
        },
      ],
    });
  };

  const abrirModalError = () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: "Se ha producido un error, intente de nuevo mas tarde",
      contenido: "",
      botones: [
        {
          nombre: "Regresar",
          click: volverATabla,
        },
      ],
    });
  };

  const volverATabla = () => {
    contextoAplicacion.setModal({ abierto: false, titulo: "", botones: [] });
    location.reload();
  };

  const manejarEliminarInformacion = async (payload) => {
    if (
      window.confirm("Desea eliminar este registro?, confirme para continuar")
    ) {
      if (banco === "Bogotá") {
        await fetch(`/api/DeleteBdB`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        })
          .then((res) => {
            if (res.status === 202) {
              abrirModalConfirmacion("eliminado");
            }
          })
          .catch((error) => {
            console.log(error);
            abrirModalError();
          });
      } else {
        await fetch(`/api/DeleteBdO`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        })
          .then((res) => {
            if (res.status === 202) {
              abrirModalConfirmacion("eliminado");
            }
          })
          .catch((error) => {
            console.log(error);
            abrirModalError();
          });
      }
    }
  };
  let columns = [
    {
      Header: (row) => <EncabezadoFondo titulo={"Editar"} />,
      id: "editar",
      className: "w-48 ",
      headerStyle: " text-white h-24 rounded-l-lg pl-1 w-48",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-center items-center h-auto rounded-l-lg ",
      Cell: (row) => (
        <CeldaConBoton
          funcionClick={() => manejarEditarInformacion(row.row.original)}
          alt={"Editar registro"}
          imagen={BotonEditar}
        />
      ),
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Eliminar"} />,
      id: "eliminar",
      className: "w-48 ",
      headerStyle: " text-white h-24 rounded-l-lg pl-1 w-48",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-center items-center h-auto rounded-l-lg ",
      Cell: (row) => (
        <CeldaConBoton
          funcionClick={() => manejarEliminarInformacion(row.row.original)}
          alt={"Eliminar registro"}
          size={"7"}
          imagen={BotonEliminar}
        />
      ),
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Url Documentos"} />,
      accessor: "urlDocumentos",
      className: "w-48 ",
      headerStyle: " text-white h-24  pl-1 w-48",
      disableFilters: true,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-center items-center h-auto rounded-l-lg",
      Cell: (row) => (
        <CeldaAbrirEnlace enlace={row.row.original.urlDocumentos} />
      ),
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Fecha Diligencia"} />,
      accessor: "fechaDiligencia",
      className: "w-48 ",
      headerStyle: " text-white h-24  pl-1 w-48",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-center items-center h-auto rounded-l-lg",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Ciudad"} />,
      accessor: "ciudad",
      className: "w-48 ",
      headerStyle: " text-white h-24  pl-1 w-48",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-center items-center h-auto rounded-l-lg",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Número de juzgado"} />,
      accessor: "numero_de_juzgado",
      className: "w-48 ",
      headerStyle: " text-white h-24  pl-1 w-48",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto w-48",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Juzgado"} />,
      accessor: "juzgado",
      className: "w-48",
      headerStyle: " text-white h-24",

      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Fag"} />,
      accessor: "fag",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
      Cell: (row) => (
        <CeldaTransformarIdEnTexto informacion={row.row.original.fagTexto} />
      ),
      Filter: FiltroListaSeleccionar,
      filter: "lista",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Número Identificación"} />,
      accessor: "numero_identificacion",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
    },

    {
      Header: (row) => <EncabezadoFondo titulo={"Nombre"} />,
      accessor: "nombre",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Radicado"} />,
      accessor: "radicado",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto ",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Obligación"} />,
      accessor: "obligacion",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Obligaciones"} />,
      accessor: "obligaciones",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Correo Electrónico"} />,
      accessor: "correo_electronico",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Tipo De Proceso"} />,
      accessor: "tipoProcesoTexto",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
      Cell: (row) => (
        <CeldaTransformarIdEnTexto
          informacion={row.row.original.tipoProcesoTexto}
        />
      ),
      Filter: FiltroListaSeleccionar,
      filter: "lista",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Radicado Completo"} />,
      accessor: "radicado_completo",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Investigación De Bienes"} />,
      accessor: "investigacion_de_bienes",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
      Cell: (row) => (
        <CeldaTransformarIdEnTexto
          informacion={row.row.original.investigacionDeBienesTexto}
        />
      ),
      Filter: FiltroListaSeleccionar,
      filter: "lista",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Etapa Procesal"} />,
      accessor: "estapaProcesalTexto",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
      Cell: (row) => (
        <CeldaTransformarIdEnTexto
          informacion={row.row.original.estapaProcesalTexto}
        />
      ),
      Filter: FiltroListaSeleccionar,
      filter: "lista",
    },

    {
      Header: (row) => <EncabezadoFondo titulo={"Etapa Procesal Medidas"} />,
      accessor: "etapaProcesalMedidasTexto",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
      Cell: (row) => (
        <CeldaTransformarIdEnTexto
          informacion={row.row.original.etapaProcesalMedidasTexto}
        />
      ),
      Filter: FiltroListaSeleccionar,
      filter: "lista",
    },
    // {
    //   Header: (row) => <EncabezadoFondo titulo={"Libranza"} />,
    //   accessor: "libranza",
    //   className: "w-48",
    //   headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
    //   disableFilters: false,
    //   cellStyles:
    //     "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
    // },
    {
      Header: (row) => <EncabezadoFondo titulo={"Fecha Ultima Revisíon"} />,
      accessor: "fecha_ult_revision",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
    },
    {
      Header: (row) => (
        <EncabezadoFondo titulo={"Fecha Presentación Demanda"} />
      ),
      accessor: "fecha_presen_demanda",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
    },
    {
      Header: (row) => (
        <EncabezadoFondo titulo={"Observaciones Cuaderno Principal"} />
      ),
      accessor: "observaciones_cuaderno_principal",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto ",
      Cell: (row) => (
        <CeldaModal
          texto={row.row.original.observaciones_cuaderno_principal}
          titulo={"Observaciones Cuaderno Principal"}
        />
      ),
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Inventario Bienes"} />,
      accessor: "inventario_bienes",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: true,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Tipo De Bien"} />,
      accessor: "tipoDeBienTexto",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
      Cell: (row) => (
        <CeldaTransformarIdEnTexto
          informacion={row.row.original.tipoDeBienTexto}
        />
      ),
      Filter: FiltroListaSeleccionar,
      filter: "lista",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Fmi 1"} />,
      accessor: "fmi_1",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Fmi 2"} />,
      accessor: "fmi_2",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Placa 1"} />,
      accessor: "placa_1",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Placa 2"} />,
      accessor: "placa_2",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Registro Mercantil"} />,
      accessor: "registro_mercantil",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Pagador"} />,
      accessor: "pagador",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Titulos Judiciales"} />,
      accessor: "titulos_judiciales",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Negociación"} />,
      accessor: "negociacionTexto",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24  ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto ",
      Cell: (row) => (
        <CeldaTransformarIdEnTexto
          informacion={row.row.original.negociacionTexto}
        />
      ),
      Filter: FiltroListaSeleccionar,
      filter: "lista",
    },

    {
      Header: (row) => <EncabezadoFondo titulo={"Factura Avance Procesal"} />,
      accessor: "facturaAvanceProcesalTexto",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24  ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto ",
      Cell: (row) => (
        <CeldaTransformarIdEnTexto
          informacion={row.row.original.facturaAvanceProcesalTexto}
        />
      ),
      Filter: FiltroListaSeleccionar,
      filter: "lista",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Pago Avance Procesal"} />,
      accessor: "pagoAvanceProcesalTexto",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24  ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto ",
      Cell: (row) => (
        <CeldaTransformarIdEnTexto
          informacion={row.row.original.pagoAvanceProcesalTexto}
        />
      ),
      Filter: FiltroListaSeleccionar,
      filter: "lista",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Fecha de Recepción"} />,
      accessor: "FechaRecepcion",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
    },

    {
      Header: (row) => <EncabezadoFondo titulo={"Observaciones"} />,
      accessor: "observaciones",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24  ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto ",
      Cell: (row) => (
        <CeldaModal
          texto={row.row.original.observaciones}
          titulo={"Observaciones"}
        />
      ),
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Plan Compartir"} />,
      accessor: "plan_compartir",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 ",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto",
      Cell: (row) => (
        <CeldaTransformarIdEnTexto
          informacion={row.row.original.planCompartirTexto}
        />
      ),
      Filter: FiltroListaSeleccionar,
      filter: "lista",
    },
    {
      Header: (row) => <EncabezadoFondo titulo={"Medida Efectiva"} />,
      accessor: "medida_efectiva",
      className: "w-48",
      headerStyle: "bg-cendiatra-verde-7 text-white h-24 rounded-r-lg",
      disableFilters: false,
      cellStyles:
        "text-13px text-beltranColorGris flex justify-start text-start pl-5 items-center h-auto rounded-r-lg",
      Cell: (row) => (
        <CeldaTransformarIdEnTexto
          informacion={row.row.original.medidaEfectivaTexto}
        />
      ),
      Filter: FiltroListaSeleccionar,
      filter: "lista",
    },
  ];

  const manejarCrearNuevo = () => {
    // const data = {
    //   titulo: "Crear proceso",
    //   banco: banco,
    //   tituloBoton: "Crear",
    // };

    // const handleClick = () => {
    //   window.open(
    //     "/creareditarprocesos",
    //     localStorage.setItem("items", JSON.stringify(data))
    //   );
    // };

    // return <button onClick={handleClick()}></button>;
    navigate("/creareditarprocesos", {
      state: { titulo: "Crear proceso", banco: banco, tituloBoton: "Crear" },
    });
  };

  return (
    <div className="w-full  flex flex-wrap justify-center items-center overflow-y-hidden">
      <div className="flex justify-end h-7 w-full">
        <NavLink to="/">
          <img className="h-7" src={imagenCerrar} alt="close" />
        </NavLink>
      </div>
      <div className="w-full flex justify-between items-center mb-5">
        <div className="w-1/3 flex justify-start ">
          <BotonSimple
            texto={"Limpiar Filtros"}
            funcionClick={() => setCambiarFiltro(!cambiarFiltro)}
            tipoDeBoton={"button"}
          />
        </div>
        <div className="w-1/3">
          {" "}
          <span className="w-full text-beltranColorPrimario text-3xl font-bold ">
            Banco {banco === "Bogotá" ? `De ${banco}` : banco}
          </span>
        </div>
        <div className="w-1/3 flex justify-evenly items-center">
          <BotonSimple
            texto={"Crear Nuevo"}
            funcionClick={manejarCrearNuevo}
            tipoDeBoton={"button"}
          />
          <ExportarCSV
            nombreBoton={"Exportar Datos"}
            nombreArchivo={`Banco ${
              banco === "Bogotá" ? `De ${banco}` : banco
            }`}
            encabezados={encabezadosExportarDatosTabla}
            datos={datosBanco ?? []}
          />
        </div>
      </div>
      <TablaConFiltro
        data={datosBanco}
        columns={columns}
        cambiarFiltro={cambiarFiltro}
      />
    </div>
  );
};

export default VerModificarProcesos;
