export const ArrayListasDesplegables = {
  TIPOS_DE_PROCESOS: [
    {
      Id: 1,
      Nombre: "EJECUTIVO SINGULAR DE MAYOR CUANTÍA",
    },
    {
      Id: 2,
      Nombre: "EJECUTIVO SINGULAR DE MENOR CUANTÍA",
    },
    {
      Id: 3,
      Nombre: "EJECUTIVO SINGULAR DE MINIMA CUANTÍA",
    },
    {
      Id: 4,
      Nombre: "EJECUTIVO CON ACCION REAL Y PERSONAL DE MAYOR CUANTIA",
    },
    {
      Id: 5,
      Nombre: "EJECUTIVO CON ACCION REAL Y PERSONAL DE MENOR CUANTIA",
    },
    {
      Id: 6,
      Nombre: "EJECUTIVO CON ACCION REAL Y PERSONAL DE MINIMA CUANTIA",
    },
    {
      Id: 7,
      Nombre: "EJECUTIVO PRENDARIO DE MAYOR CUANTÍA",
    },
    {
      Id: 8,
      Nombre: "EJECUTIVO PRENDARIO DE MENOR CUANTÍA",
    },
    {
      Id: 9,
      Nombre: "EJECUTIVO PRENDARIO DE MINIMA CUANTÍA",
    },
    {
      Id: 10,
      Nombre: "EJECUTIVO MIXTO DE MAYOR CUANTIA",
    },
    {
      Id: 11,
      Nombre: "EJECUTIVO MIXTO DE MENOR CUANTIA",
    },
    {
      Id: 12,
      Nombre: "EJECUTIVO MIXTO DE MINIMA CUANTIA",
    },
    {
      Id: 13,
      Nombre: "PROCESO DE APREHENSION POR PAGO DIRECTO",
    },
    {
      Id: 14,
      Nombre: "PROCESO DE RESTITUCION DE BIEN MUEBLE",
    },
    {
      Id: 15,
      Nombre: "PROCESO DE RESTITUCION DE BIEN INMUEBLE",
    },

    {
      Id: 16,
      Nombre: "DECLARATIVO VERBAL DE MAYOR CUANTIA",
    },

    {
      Id: 17,
      Nombre: "DECLARATIVO VERBAL DE MENOR CUANTIA",
    },
    {
      Id: 18,
      Nombre: "DECLARATIVO VERBAL DE MINIMA CUANTIA",
    },
    {
      Id: 19,
      Nombre: "EJECUTIVO HIPOTECARIO DE MAYOR CUANTIA",
    },
    {
      Id: 20,
      Nombre: "EJECUTIVO HIPOTECARIO DE MENOR CUANTIA",
    },
    {
      Id: 21,
      Nombre: "EJECUTIVO HIPOTECARIO DE MINIMA CUANTIA",
    },
    {
      Id: 22,
      Nombre: "CURADURÍA",
    },
    {
      Id: 23,
      Nombre: "INSOLVENCIA PERSONA NATURAL NO COMERCIANTE",
    },
    {
      Id: 24,
      Nombre: "INSOLVENCIA EMPRESARIAL O COMERCIAL",
    },
    {
      Id: 25,
      Nombre: "LIQUIDACIÓN PATRIMONIAL",
    },
  ],
  ETAPA_PROCESAL: [
    {
      Id: 1,
      Nombre: "ADMITE DEMANDA",
    },
    {
      Id: 2,
      Nombre: "DICTA SENTENCIA FAVORABLE",
    },
    {
      Id: 3,
      Nombre: "DICTA SENTENCIA DESFAVORABLE",
    },
    {
      Id: 4,
      Nombre: "INADMISION DE LA DEMANDA",
    },
    {
      Id: 5,
      Nombre: "LIQUIDACIÓN APROBADA",
    },
    {
      Id: 6,
      Nombre: "PRESENTACIÓN DE LA DEMANDA ",
    },
    {
      Id: 7,
      Nombre: "PRESENTACION DE LIQUIDACIÓN",
    },
    {
      Id: 8,
      Nombre: "ADMITE SOLICITUD PAGO DIRECTO",
    },
    {
      Id: 9,
      Nombre: "RECHAZA DEMANDA",
    },
    {
      Id: 10,
      Nombre: "APRUEBA LIQUIDACION",
    },
    {
      Id: 10,
      Nombre: "APRUEBA LIQUIDACION",
    },
    {
      Id: 11,
      Nombre: "REQUIERE POR NOTIFICACION",
    },
    {
      Id: 12,
      Nombre: "NOTIFICACION ELECTRONICA",
    },
    {
      Id: 13,
      Nombre: "NOTIFICACION PERSONAL",
    },
    {
      Id: 14,
      Nombre: "NOTIFICACION POR AVISO",
    },

    {
      Id: 15,
      Nombre: "PLANTEA EXCEPCIONES",
    },
    {
      Id: 16,
      Nombre: "CONTESTA EXCEPCIONES",
    },
    {
      Id: 17,
      Nombre: "EMPLAZAMIENTO",
    },
    {
      Id: 18,
      Nombre: "DESIGNA CURADOR",
    },
    {
      Id: 19,
      Nombre: "LIBRA MANDAMIENTO",
    },
    {
      Id: 20,
      Nombre: "RECHAZO POR COMPETENCIA",
    },
    {
      Id: 21,
      Nombre: "TERMIANCION POR PAGO TOTAL",
    },
    {
      Id: 22,
      Nombre: "DESISTIMIENTO TACITO",
    },
    {
      Id: 23,
      Nombre: "DEVOLUCION DOCUMENTOS",
    },
    {
      Id: 24,
      Nombre: "TERMINACION POR RESTITUCION DEL PLAZO",
    },
    {
      Id: 25,
      Nombre: "NULIDAD",
    },
    {
      Id: 26,
      Nombre: "RECURSO REPOSICION",
    },
    {
      Id: 27,
      Nombre: "RECURSO APELACION",
    },
    {
      Id: 28,
      Nombre: "OTROS RECURSOS",
    },
    {
      Id: 29,
      Nombre: "NOTIFICACIÓN PAGO DIRECTO",
    },
    {
      Id: 30,
      Nombre: "CESIÓN DE DERECHOS",
    },
    {
      Id: 31,
      Nombre: "RENUNCIA PODER",
    },
    {
      Id: 32,
      Nombre: "TRASLADO LIQUIDACIÓN",
    },
    {
      Id: 33,
      Nombre: "SUBSANACIÓN",
    },
    {
      Id: 34,
      Nombre: "SUSPENSIÓN DE PROCESO",
    },
    {
      Id: 35,
      Nombre: "LIQUIDACIÓN COSTAS",
    },
    {
      Id: 36,
      Nombre: "VENTA DE CARTERA",
    },
    {
      Id: 37,
      Nombre: "CONTABILIZACIÓN EN CURSO",
    },
    {
      Id: 38,
      Nombre: "PRESENTACIÓN ACREENCIAS",
    },
    {
      Id: 39,
      Nombre: "GRADUACIÓN Y CALIFICACIÓN DE CRÉDITOS",
    },
    {
      Id: 40,
      Nombre: "OBJECIONES",
    },
    {
      Id: 41,
      Nombre: "APROBACIÓN DEL ACUERDO",
    },
    {
      Id: 42,
      Nombre: "CONTROVERSIAS",
    },
    {
      Id: 43,
      Nombre: "FRACASO DEL ACUERDO",
    },

    {
      Id: 44,
      Nombre: "ACTUALIZACIÓN DE BIENES Y AVALÚOS",
    },
    {
      Id: 45,
      Nombre: "PROYECTO DE ADJUDICACIÓN",
    },
    {
      Id: 46,
      Nombre: "AUDIENCIA DE ADJUDICACIÓN",
    },
    {
      Id: 47,
      Nombre: "TERMINACIÓN EN TRÁMITE",
    },
    {
      Id: 48,
      Nombre: "REETIRO DE LA DEMANDA",
    },
    {
      Id: 49,
      Nombre:
        "INCLUIR ETAPA INMUEBLE RESTITUIDO O TERMINACION POR RESTITUCION DEL INMUEBLE",
    },
  ],
  FACTURA_AVANCE_PROCESAL: [
    {
      Id: 1,
      Nombre: "MANDAMIENTO",
    },
    {
      Id: 2,
      Nombre: "SENTENCIA",
    },
  ],
  PAGO_AVANCE_PROCESAL: [
    {
      Id: 1,
      Nombre: "SENTENCIA",
    },
    {
      Id: 2,
      Nombre: "LIQUIDACIÓN",
    },
  ],
  NEGOCIACION: [
    {
      Id: 1,
      Nombre: "CUMPLIO ACUERDO",
    },
    {
      Id: 2,
      Nombre: "EN CUMPLIMIENTO",
    },
    {
      Id: 3,
      Nombre: "ACUERDO INCUMPLIDO",
    },
    {
      Id: 4,
      Nombre: "SIN ACUERDO",
    },
  ],
  ETAPA_PROCESAL_MEDIDAS: [
    {
      Id: 1,
      Nombre: "SOLICITUD EMBARGO VEHICULO",
    },
    {
      Id: 2,
      Nombre: "SOLICITUD EMBARGO INMUEBLE",
    },
    {
      Id: 3,
      Nombre: "SOLICITUD EMBARGO ESTABLECIMIENTO",
    },
    {
      Id: 4,
      Nombre: "SOLICITUD EMBARGO INNOMINADA",
    },
    {
      Id: 5,
      Nombre: "DECRETO EMBARGO VEHICULO",
    },
    {
      Id: 6,
      Nombre: "DECRETO EMBARGO INMUEBLE",
    },
    {
      Id: 7,
      Nombre: "DECRETO EMBARGO ESTABLECIMIENTO",
    },
    {
      Id: 8,
      Nombre: "DECRETO EMBARGO INNOMINADA",
    },
    {
      Id: 9,
      Nombre: "ELABORA OFICIOS",
    },
    {
      Id: 10,
      Nombre: "RADICA OFICIOS",
    },
    {
      Id: 11,
      Nombre: "DECRETA ORDEN DE RETENCION",
    },
    {
      Id: 12,
      Nombre: "RADICA OFICIO DE RETENCION",
    },
    {
      Id: 13,
      Nombre: "ORDENA SECUESTRO",
    },
    {
      Id: 14,
      Nombre: "PRACTICA DE SECUESTRO",
    },
    {
      Id: 15,
      Nombre: "PAGO DERECHOS DE EMBARGO",
    },
    {
      Id: 16,
      Nombre: "PROFIERE DESPACHO COMISORIO",
    },
    {
      Id: 17,
      Nombre: "RADICA DESPACHO COMISORIO",
    },
    {
      Id: 18,
      Nombre: "FIJA FECHA SECUESTRO",
    },
    {
      Id: 19,
      Nombre: "AVALUO",
    },
    {
      Id: 20,
      Nombre: "REMATE",
    },
    {
      Id: 21,
      Nombre: "LEVANTAMIENTO ORDEN DE RETENCION",
    },
    {
      Id: 22,
      Nombre: "EMBARGO DE SALARIO",
    },
  ],
  OPCIONES_SI_NO: [
    {
      Id: 1,
      Nombre: "SI",
    },
    {
      Id: 2,
      Nombre: "NO",
    },
  ],
  OPCIONES_POSITIVO_NEGATIVO: [
    {
      Id: 1,
      Nombre: "POSITIVO",
    },
    {
      Id: 2,
      Nombre: "NEGATIVO",
    },
  ],
  TIPO_DE_BIEN: [
    {
      Id: 1,
      Nombre: "VEHICULO",
    },
    {
      Id: 2,
      Nombre: "INMUEBLE",
    },
    {
      Id: 2,
      Nombre: "INMUEBLE",
    },
    {
      Id: 3,
      Nombre: "SALARIO",
    },
    {
      Id: 4,
      Nombre: "CUENTAS",
    },
    {
      Id: 5,
      Nombre: "ACCIONES CUOTAS PARTES",
    },
    {
      Id: 6,
      Nombre: "ESTABLECIMIENTO DE COMERCIO",
    },
    {
      Id: 7,
      Nombre: "INNOMINADA",
    },
    {
      Id: 8,
      Nombre: "VARIOS",
    },
  ],
  FONDOS: [
    {
      Id: 1,
      Nombre: "FAG",
    },
    {
      Id: 2,
      Nombre: "FNG",
    },
    {
      Id: 3,
      Nombre: "N/A",
    },
  ],
};
