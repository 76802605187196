import React from "react";

const AreaDeTexto = ({
  estilosAreaDeTexto,
  titulo,
  deshabilitado,
  placeholder,
  valor,
  cambioValor,
  id,
}) => {
  return (
    <div className={"w-full  flex justify-start items-center flex-wrap"}>
      <label className=" text-13px w-full text-beltranColorPrimario font-medium text-start my-5px">
        {titulo}
      </label>
      <textarea
        className={estilosAreaDeTexto}
        disabled={deshabilitado}
        placeholder={placeholder}
        onChange={cambioValor}
        value={valor}
        id={id}
      />
    </div>
  );
};

export default AreaDeTexto;
