import { ArrayListasDesplegables } from "./ListasDesplegables";

export const obtenerTiposDeProcesoPorId = async (Id) => {
  return ArrayListasDesplegables.TIPOS_DE_PROCESOS.find(
    (elemento) => elemento.Id === Id
  );
};

export const obtenerEtapaProcesalPorId = async (Id) => {
  return ArrayListasDesplegables.ETAPA_PROCESAL.find(
    (elemento) => elemento.Id === Id
  );
};

export const obtenerEtapaProcesalMedidasPorId = async (Id) => {
  return ArrayListasDesplegables.ETAPA_PROCESAL_MEDIDAS.find(
    (elemento) => elemento.Id === Id
  );
};

export const obtenerTipoDeBienPorId = async (Id) => {
  return ArrayListasDesplegables.TIPO_DE_BIEN.find(
    (elemento) => elemento.Id === Id
  );
};
export const obtenerNegociacionId = async (Id) => {
  return ArrayListasDesplegables.NEGOCIACION.find(
    (elemento) => elemento.Id === Id
  );
};

export const obtenerFacturaAvanceProcesalId = async (Id) => {
  return ArrayListasDesplegables.FACTURA_AVANCE_PROCESAL.find(
    (elemento) => elemento.Id === Id
  );
};
export const obtenerPagoAvanceProcesalId = async (Id) => {
  return ArrayListasDesplegables.PAGO_AVANCE_PROCESAL.find(
    (elemento) => elemento.Id === Id
  );
};
