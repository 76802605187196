import React, { useEffect } from "react";
import { ContenedorPagina } from "../components/contenedores/ContenedorPagina";
import VerModificarProcesos from "../components/modificarProcesos/VerModificarProcesos";

const Procesos = ({ banco }) => {
  return (
    <ContenedorPagina tituloPagina={"Ver y Editar Procesos"}>
      <VerModificarProcesos banco={banco} />
    </ContenedorPagina>
  );
};

export default Procesos;
