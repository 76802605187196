import React, { useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { ArrayInputs } from "../../utilidades/Arrays";
import Input from "../inputs/Input";
import AreaDeTexto from "../inputs/AreaDeTexto";
import ListaDesplegable from "../inputs/ListaDesplegable";
import { useLocation } from "react-router-dom";
import BotonSimple from "../botones/BotonSimple";
import { ContextoAplicacion } from "../../contextos/ContextoAplicacion";
import { useNavigate } from "react-router-dom";
import imagenCerrar from "../../imagenes/Salir.png";

const FormularioCrearProceso = () => {
  const { state } = useLocation();

  const navigate = useNavigate();
  const contextoAplicacion = useContext(ContextoAplicacion);
  const {
    trigger,
    handleSubmit,
    reset,
    control,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {},
    mode: "onChange",
  });

  useEffect(() => {
    if (state.datosProceso) {
      reset(state.datosProceso);
    }
    trigger();
  }, []);

  const abrirModalConfirmacion = (transaccion) => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: `Se ha ${transaccion} el proceso correctamente`,
      contenido: "",
      botones: [
        {
          nombre: "Regresar",
          click: volverATabla,
        },
      ],
    });
  };

  const abrirModalError = () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: "Se ha producido un error, intente de nuevo mas tarde",
      contenido: "",
      botones: [
        {
          nombre: "Regresar",
          click: volverATabla,
        },
      ],
    });
  };

  const volverATabla = () => {
    contextoAplicacion.setModal({ abierto: false, titulo: "", botones: [] });
    if (state.banco === "Bogotá") {
      // localStorage.removeItem("items");
      // window.close();

      navigate("/bancodebogota");
    } else {
      // localStorage.removeItem("items");
      // window.close();
      navigate("/bancoPopular");
    }
  };

  const manejarSubmit = async (data) => {
    contextoAplicacion.setCargando(true);
    let payload = {
      partitionKey: state?.datosProceso?.idProceso ?? "",
      rowKey: state?.datosProceso?.idFila ?? "",
      FECHA_DILIGENCIA: data.fechaDiligencia,
      CIUDAD: data.ciudad,
      NUMERO_DE_JUZGADO: data.numero_de_juzgado,
      JUZGADO: data.juzgado,
      FAG: data.fag,
      NUMERO_IDENTIFICACION: data.numero_identificacion,
      NOMBRE: data.nombre,
      RADICADO: data.radicado,
      OBLIGACION: data.obligacion,
      OBLIGACIONES: data.obligaciones,
      CORREO_ELECTRONICO: data.correo_electronico,
      TIPO_DE_PROCESO: data.tipo_de_proceso,
      RADICADO_COMPLETO: data.radicado_completo,
      INVESTIGACION_DE_BIENES: data.investigacion_de_bienes,
      ETAPA_PROCESAL: data.etapa_procesal,
      ETAPA_PROCESAL_MEDIDAS: data.etapa_procesal_medidas,
      LIBRANZA: data.libranza,
      FECHA_ULT_REVISION: data.fecha_ult_revision,
      FECHA_PRESEN_DEMANDA: data.fecha_presen_demanda,
      OBSERVACIONES_CUADERNO_PRINCIPAL: data.observaciones_cuaderno_principal,
      INVENTARIO_BIENES: data.inventario_bienes,
      TIPO_DE_BIEN: data.tipo_de_bien,
      FMI_1: data.fmi_1,
      FMI_2: data.fmi_2,
      PLACA_1: data.placa_1,
      PLACA_2: data.placa_2,
      REGISTRO_MERCANTIL: data.registro_mercantil,
      PAGADOR: data.pagador,
      TITULOS_JUDICIALES: data.titulos_judiciales,
      PAGO_AVANCE_PROCESAL: data.pagoAvanceProcesal,
      FACTURA_AVANCE_PROCESAL: data.facturaAvanceProcesal,
      NEGOCIACION: data.negociacion,
      FECHA_RECEPCION: data.FechaRecepcion,
      OBSERVACIONES: data.observaciones,
      url_documento: data.urlDocumentos,
      MEDIDA_EFECTIVA: data.medida_efectiva,
      PLAN_COMPARTIR: data.plan_compartir,
    };

    if (state.titulo.includes("Editar")) {
      if (state.banco === "Bogotá") {
        await fetch(`/api/UpdateBdB`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        })
          .then((res) => {
            if (res.status === 202) {
              abrirModalConfirmacion("editado");
            }
          })
          .catch((error) => {
            console.log(error);
            abrirModalError();
          });
      } else {
        await fetch(`/api/UpdateBdO`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        })
          .then((res) => {
            if (res.status === 202) {
              abrirModalConfirmacion("editado");
            }
          })
          .catch((error) => {
            console.log(error);
            abrirModalError();
          });
      }
    } else {
      if (state.banco === "Bogotá") {
        await fetch(`/api/CreateBdB`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        })
          .then((res) => {
            if (res.status === 201) {
              abrirModalConfirmacion("creado");
            }
          })
          .catch((error) => {
            console.log(error);
            abrirModalError();
          });
      } else {
        await fetch(`/api/CreateBdO`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        })
          .then((res) => {
            if (res.status === 201) {
              abrirModalConfirmacion("creado");
            }
          })
          .catch((error) => {
            console.log(error);
            abrirModalError();
          });
      }
    }
    contextoAplicacion.setCargando(false);
  };

  const manejarTipoDeCampo = (elemento) => {
    switch (elemento.campo) {
      case "INPUT":
        return (
          <Controller
            name={elemento.nombre}
            control={control}
            rules={{
              required: elemento.requerido,
            }}
            render={({ field: { onChange, value } }) => (
              <Input
                deshabilitado={false}
                estilosInput={
                  Object.keys(errors).find(
                    (element) => element === elemento.nombre
                  )
                    ? "appearance-none relative block w-full p-1.5 border border-beltranColorRojo text-beltranColorGris  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm "
                    : "appearance-none relative block w-full p-1.5 border border-beltranColorPrimario text-beltranColorGris rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm "
                }
                erroresFormulario={errors}
                estilosContenedor="w-full"
                mostrarErrores={true}
                nombre={elemento.nombre}
                tipo={elemento.tipo}
                titulo={elemento.titulo}
                valor={value}
                onChange={onChange}
              />
            )}
          />
        );
      case "AREA":
        return (
          <Controller
            name={elemento.nombre}
            control={control}
            rules={{
              required: elemento.requerido,
            }}
            render={({ field: { onChange, value } }) => (
              <AreaDeTexto
                estilosAreaDeTexto={
                  Object.keys(errors).find(
                    (element) => element === elemento.nombre
                  )
                    ? "h-20 mt-3 rounded w-full  p-1.5 border border-beltranColorRojo text-beltranColorGris rounded-lg focus:outline-none  sm:text-sm"
                    : "h-20 mt-3 rounded w-full  p-1.5 border border-beltranColorPrimario text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm"
                }
                titulo={elemento.titulo}
                valor={value}
                cambioValor={onChange}
              />
            )}
          />
        );
      case "LISTA":
        return (
          <Controller
            name={elemento.nombre}
            control={control}
            rules={{
              required: elemento.requerido,
            }}
            render={({ field: { onChange, value } }) => (
              <ListaDesplegable
                onChange={onChange}
                estilosLista={
                  Object.keys(errors).find(
                    (element) => element === elemento.nombre
                  )
                    ? "appearance-none relative block w-full p-1.5 border border-beltranColorRojo text-beltranColorGris  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra-rojo-1 focus:z-10 sm:text-sm "
                    : "appearance-none relative block w-full p-1.5 border border-beltranColorPrimario text-beltranColorGris rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm "
                }
                titulo={elemento.titulo}
                opciones={elemento.opcionesLista}
                valor={value}
              />
            )}
          />
        );
    }
  };

  return (
    <form
      onSubmit={(e) => handleSubmit(manejarSubmit)(e)}
      className="w-full flex justify-between items-center flex-wrap"
    >
      <div className="flex justify-end h-7 w-full" onClick={volverATabla}>
        <img className="h-7" src={imagenCerrar} alt="close" />
      </div>
      <span className="w-full text-beltranColorPrimario text-3xl font-bold  mb-10">
        {state.titulo} Banco{" "}
        {state.banco === "Bogotá" ? `De ${state.banco}` : state.banco}
      </span>
      {ArrayInputs.map((elemento) => (
        <div
          className={`${
            elemento.campo === "AREA" ? "w-full" : "w-30%"
          } flex justify-start flex-wrap my-2`}
          key={elemento.nombre}
        >
          {manejarTipoDeCampo(elemento)}
        </div>
      ))}
      <div className="w-full flex items-center justify-center my-5">
        <BotonSimple
          texto={state.tituloBoton}
          tipoDeBoton={"submit"}
          deshabilitado={!isValid}
        />
      </div>
    </form>
  );
};

export default FormularioCrearProceso;
