import React, { useContext } from "react";
import { SignOutButton } from "./components/SignOutButton";
import { BrowserRouter } from "react-router-dom";
import BarraNavegacion from "./components/navegacion/BarraNavegacion";
import Spinner from "./components/Spinner";
import Modal from "./components/Modales/Modal";
import { ContextoAplicacion } from "./contextos/ContextoAplicacion";

import Router from "./Router.js";

const App = () => {
  const contextoAplicacion = useContext(ContextoAplicacion);
  return (
    <>
      {/* <SignOutButton /> */}
      <BrowserRouter>
        <Modal />
        {contextoAplicacion.cargando ? (
          <Spinner message={"Béltran Mejía"} />
        ) : null}
        <BarraNavegacion />
        <Router />
      </BrowserRouter>
    </>
  );
};

export default App;
