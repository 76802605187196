import React from "react";

const EncabezadoFondo = ({ titulo, Fila1, Fila2 }) => {
  return (
    <div className="h-full w-full flex">
      <div className="w-48 flex justify-center items-center bg-beltranColorSecundario my-1 mx-0.5 rounded-md text-14px ">
        {titulo ? (
          titulo
        ) : (
          <div className="w-full flex flex-col justify-evenly items-center">
            <span className="w-full flex justify-evenly items-center">
              {Fila1}
            </span>
            <span className="w-full flex justify-evenly items-center">
              {Fila2}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default EncabezadoFondo;
