import React, { useEffect } from "react";
import { SignInButton } from "../components/SignInButton";
import BotonSimple from "../components/botones/BotonSimple";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import LogoConTexto from "../imagenes/LogoBeltranConTexto.png";

const Landing = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType) => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.log(e);
    });
  };

  return (
    <div className="min-h-screen w-full flex flex-wrap items-center justify-center py-12 px-4 sm:px-6">
      <div className="bg-white w-4/12 p-4 rounded rounded-t-3xl rounded-b-3xl border border-opacity-50 border-beltranColorPrimario shadow-2xl mt-5">
        <div className="w-full flex justify-center items-center flex-wrap bg-beltranColorPrimario px-8 pt-2 rounded-t-3xl rounded-b-3xl">
          <img
            className="w-10/12 border-opacity-50 my-2"
            src={LogoConTexto}
            alt="fondo"
          />
        </div>
        <div className="w-full flex h-28 justify-center items-center flex-wrap bg-white px-8 pt-5 rounded-t-3xl rounded-b-3xl">
          <BotonSimple
            texto={"Autenticarse"}
            funcionClick={() => handleLogin()}
            tipoDeBoton={"button"}
          />
        </div>
      </div>
    </div>
  );
};

export default Landing;
